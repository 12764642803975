import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Vector from '../../Icons/Vector'

const VectorContainer = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;

  svg {
    //display: inline-block;
    width: 100%;
    height: 100%;
  }
`

const Ball = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.text};

  @media (max-width: 48em) {
    left: 1rem;
  }

  align-items: center;
`

const DrawSvg = () => {
  const ref = useRef(null)
  const ballRef = useRef(null)

  gsap.registerPlugin(ScrollTrigger)
  useLayoutEffect(() => {
    let element = ref.current

    let svg = document.getElementsByClassName('svg-path')[0]

    const length = svg.getTotalLength()

    //   start positioning of svg drawing
    svg.style.strokeDasharray = length

    // Hide svg before scrolling start
    svg.style.strokeDashoffset = length

    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top center',
        end: 'bottom bottom',
        onUpdate: (self) => {
          const draw = length * self.progress

          // also reverse the drawing when scroll goes up
          svg.style.strokeDashoffset = length - draw
        },
        onToggle: (self) => {
          if (self.isActive) {
            // console.log("Scrolling is active");
            ballRef.current.style.display = 'none'
          } else {
            // console.log("Scrolling is not active");
            ballRef.current.style.display = 'inline-block'
          }
        },
      },
    })

    return () => {
      if (t1) t1.kill()
    }
  }, [])

  return (
    <>
      <Ball ref={ballRef} />
      <VectorContainer ref={ref}>
        <Vector />
      </VectorContainer>
    </>
  )
}

export default DrawSvg
