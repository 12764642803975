import img1 from './assets/100.png'
import img2 from './assets/1500.png'
import img3 from './assets/8237.png'
import img4 from './assets/3500.png'
import img5 from './assets/nic.png'
import img6 from './assets/lee.png'
import img7 from './assets/hunter.png'
import img8 from './assets/1.png'

const array = [img1, img2, img3, img4, img5, img6, img7, img8]
export default array
